import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import Back from 'components/Back';
import colors from '../../constants/colors';

import icon from '../../assets/icons/logo.svg';
import activity from './ic_community.svg';
import home from './ic_home.svg';
import profile from './ic_profile.svg';
import programs from './ic_programs.svg';
import schedule from './ic_schedule.svg';
import activityActive from './ic_community_active.svg';
import homeActive from './ic_home_active.svg';
import profileActive from './ic_profile_active.svg';
import programsActive from './ic_programs_active.svg';
import scheduleActive from './ic_schedule_active.svg';
import { hideOnMobile } from '../../constants/globalStyles';

const MENU_ICONS = {
  activity: {
    normal: activity,
    active: activityActive,
  },
  profile: {
    normal: profile,
    active: profileActive,
  },
  schedule: {
    normal: schedule,
    active: scheduleActive,
  },
  programs: {
    normal: programs,
    active: programsActive,
  },
  home: {
    normal: home,
    active: homeActive,
  },
};

// ex means extra navigation menu
const ExNav = styled.nav`
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  overflow: hidden;
  min-width: 1px;

  @media screen and (max-width: 992px) {
    display: none;
    position: fixed;
    height: 55px;
    background: ${colors.main.white};
    top: 0;
    left: 0;
    right: 0;
    width: 100%;

    a {
      &:first-of-type {
        margin-right: 0;
      }
    }
  }
`;

const Wrapper = styled.header`
  background: ${colors.main.white};
  box-shadow: 0 0 26px 0 ${rgba(colors.main.black, 0.1)};
  position: absolute;
  height: 85px;
  top: 0;
  transform: translateZ(0);
  left: 0;
  right: 0;
  z-index: 1040;

  @media screen and (max-width: 992px) {
    position: fixed;
    box-shadow: 0 4px 26px ${rgba(colors.main.black, 0.1)};
    top: initial;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;

    ${props =>
      !props.exNav
        ? css`
            height: 0 !important;
          `
        : css`
            height: 55px;
          `}
  }
`;

const Nav = styled.nav`
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 992px) {
    background: ${colors.main.white};
    box-shadow: 0 4px 26px 0 ${rgba(colors.main.black, 0.16)};
    position: fixed;
    display: block;
    bottom: 0;
    height: calc(60px + env(safe-area-inset-bottom));
    padding-bottom: env(safe-area-inset-bottom);
    left: 0;
    right: 0;
    z-index: 1000;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  width: 990px;

  @media screen and (max-width: 992px) {
    width: 89.333%;
  }

  @media screen and (min-width: 767px) and (max-width: 1100px) and (orientation: portrait) {
    width: 930px;
  }
`;

const Logo = styled.i`
  display: block;
  background: url(${icon}) 50% 50% no-repeat;
  background-size: contain;
  height: 44px;
  width: 80px;
  ${hideOnMobile}
`;

const NavItems = styled.ul`
  display: flex;
  align-items: center;
  padding-top: 4px;

  @media screen and (max-width: 992px) {
    justify-content: space-between;
    height: 100%;
    margin: 0 auto;
    padding: 0 10px;
    transform: none;
    width: 89.333%;
    max-width: 400px;
  }

  ${props =>
    props.isLoading &&
    css`
      ${NavItem} {
        pointer-events: none;
      }
    `}
`;

const NavItemIcon = styled.i`
  display: none;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
  margin: 0 auto;
  height: 25px;
  width: 25px;

  @media screen and (max-width: 992px) {
    display: block;
  }
`;

// rewrite with text component
const NavItemText = styled.span`
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
  transition: color 0.3s;
  letter-spacing: 0.1em;

  &:hover {
    color: ${colors.main.blue};
  }

  @media screen and (max-width: 992px) {
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: 0.02em;
  }
`;

const NavItemDetails = styled.div`
  ${props =>
    props.exClassName || props.className
      ? css`
          ${NavItemText} {
            color: ${colors.main.blue};

            @media screen and (min-width: 1100px) {
              &:hover {
                color: ${colors.main.blue};
              }
            }

            @media screen and (max-width: 992px) {
              color: ${colors.main.black};
            }
          }
          ${NavItemIcon} {
            background: url(${innerProps => MENU_ICONS[innerProps.page]?.active}) 50% 50% no-repeat;
          }
        `
      : css`
          ${NavItemText} {
            color: ${colors.main.black};

            @media screen and (min-width: 1100px) {
              &:hover {
                color: ${colors.main.blue};
              }
            }

            @media screen and (max-width: 992px) {
              color: ${colors.main.grey};
            }
          }
          ${NavItemIcon} {
            background: url(${innerProps => MENU_ICONS[innerProps.page]?.normal}) 50% 50% no-repeat;
          }
        `}
`;

const NavItem = styled.li`
  position: relative;

  &:not(:last-of-type) {
    margin-right: 40px;
  }

  @media screen and (max-width: 992px) {
    &:not(:last-of-type) {
      margin-right: 0;
    }
  }
`;

const StyledBack = styled(Back)`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  transition: color 0.3s;
  margin-left: 60px;
  width: 350px;
  z-index: 1060;

  svg {
    margin-right: 20px;
  }

  &:hover {
    color: ${colors.main.blue};

    path {
      fill: ${colors.main.blue};
    }

    & > div {
      h4 {
        color: ${colors.main.blue};
      }
    }
  }
`;

const StyledBackTitle = styled.div`
  width: calc(100% - 45px);
`;

export {
  Wrapper,
  Container,
  ExNav,
  Nav,
  NavItemDetails,
  NavItems,
  NavItem,
  NavItemText,
  NavItemIcon,
  Logo,
  StyledBack,
  StyledBackTitle,
};
