import React, { FC, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import NProgress from 'components/NProgress';
import LinkWithRouter from '../LinkWithRouter';
import Link from '../Link';
import Text from '../core/Text';

import {
  Wrapper,
  Logo,
  Container,
  NavItems,
  NavItem,
  NavItemDetails,
  NavItemText,
  NavItemIcon,
  Nav,
  ExNav,
  StyledBack,
  StyledBackTitle,
} from './styles';

interface Props {
  className?: string;
  children?: React.ReactNode;
  backTitle?: string;
  backTo?: string;
  backSubtitle?: string;
  isDisplay?: boolean;
  onBackClick?: () => void;
}

const Header: FC<Props> = ({
  className,
  children,
  isDisplay = true,
  backTitle,
  backTo,
  backSubtitle,
  onBackClick,
}) => {
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router]);

  const handleBackClick = useCallback(() => {
    if (backTo) {
      router.push(backTo);
    }
    onBackClick?.();
  }, [backTo, onBackClick, router]);

  if (!isDisplay) {
    return null;
  }

  return (
    <Wrapper className={className}>
      <Container>
        <ExNav>
          <Link href="/">
            <Logo />
          </Link>
          {(!!onBackClick || !!backTo) && (
            <StyledBack onClick={handleBackClick}>
              <StyledBackTitle>
                {!!backSubtitle && (
                  <Text variant="captionSmall" transform="uppercase" align="left" color="grayLight">
                    {backSubtitle}
                  </Text>
                )}
                {backTitle && (
                  <Text
                    variant="displayM"
                    tag="h4"
                    color="black"
                    transform="uppercase"
                    align="left"
                    isCondensed
                  >
                    {backTitle}
                  </Text>
                )}
              </StyledBackTitle>
            </StyledBack>
          )}
          {children}
        </ExNav>
        <Nav>
          <NProgress options={{ trickleSpeed: 50 }} showAfterMs={300} isMobile={isDisplay} />
          <NavItems isLoading={loading}>
            <NavItem>
              <LinkWithRouter activeClassName="active" href="/">
                <NavItemDetails page="home">
                  <NavItemIcon />
                  <NavItemText>Home</NavItemText>
                </NavItemDetails>
              </LinkWithRouter>
            </NavItem>
            <NavItem>
              <LinkWithRouter hasNesting activeClassName="active" href="/schedule">
                <NavItemDetails page="schedule">
                  <NavItemIcon />
                  <NavItemText>Schedule</NavItemText>
                </NavItemDetails>
              </LinkWithRouter>
            </NavItem>
            <NavItem>
              <LinkWithRouter hasNesting activeClassName="active" href="/programs">
                <NavItemDetails page="programs">
                  <NavItemIcon />
                  <NavItemText>Programs</NavItemText>
                </NavItemDetails>
              </LinkWithRouter>
            </NavItem>
            <NavItem>
              <LinkWithRouter hasNesting activeClassName="active" href="/activity">
                <NavItemDetails page="activity">
                  <NavItemIcon />
                  <NavItemText>Activity</NavItemText>
                </NavItemDetails>
              </LinkWithRouter>
            </NavItem>
            <NavItem>
              <LinkWithRouter hasNesting activeClassName="active" href="/profile">
                <NavItemDetails page="profile">
                  <NavItemIcon />
                  <NavItemText>Profile</NavItemText>
                </NavItemDetails>
              </LinkWithRouter>
            </NavItem>
          </NavItems>
        </Nav>
      </Container>
    </Wrapper>
  );
};

export default Header;
