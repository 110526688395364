import { withRouter } from 'next/router';
import Link from 'next/link';
import React, { Children } from 'react';

const LinkWithRouter = ({ router, children, target, ...props }) => {
  const child = Children.only(children);
  let className = child.props.className || '';
  const isExactRoute = router.asPath === props.href;
  const onLinkClick = isExactRoute ? e => e.preventDefault() : () => {};

  if (props.hasNesting && router.asPath.indexOf(props.href) !== -1) {
    className = `${className} ${props.activeClassName}`.trim();
  } else if (router.asPath === props.href && props.activeClassName && !className) {
    className = `${className} ${props.activeClassName}`.trim();
  }

  /* eslint-disable no-param-reassign */
  delete props.activeClassName;
  delete props.hasNesting;
  /* eslint-enable no-param-reassign */

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Link {...props}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a target={target} onClick={onLinkClick} aria-hidden="true">
        {React.cloneElement(child, { className })}
      </a>
    </Link>
  );
};

export default withRouter(LinkWithRouter);
